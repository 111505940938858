import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Outdoor Cooking`}</h1>
    <h2>{`Primary`}</h2>
    <h2>{`Backup`}</h2>
    <h2>{`Fuel`}</h2>
    <h2>{`Tools`}</h2>
    <h3>{`Temperature`}</h3>
    <h3>{`Starting`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      